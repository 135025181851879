<template>
  <div class="area_tbl">
    <div class="tbl_comm tbl_list">
      <table>
        <colgroup>
          <col style="width: 114px" />
          <!-- No -->
          <col style="width: 150px" />
          <!-- 기안금액 -->
          <col style="width: 290px" />
          <!-- 프로젝트코드 -->
          <col />
          <!-- 추가금액 -->
          <col style="width: 242px" />
          <!-- 추가/내역 -->
        </colgroup>
        <thead>
          <tr>
            <th class="align_left">No.</th>
            <th>기안금액</th>
            <th>프로젝트코드</th>
            <th>추가금액</th>
            <th class="align_right" />
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in purchasePriceList" :key="index">
            <td class="align_left">
              {{ index + 1 }}
            </td>
            <td>{{ item.draftingPrice | currency }}</td>
            <td>{{ item.projectCode }}</td>
            <td>{{ item.addPrice | currency }}</td>
            <td class="align_right">
              <div class="row_pack">
                <button
                  v-if="!isView"
                  class="btn_small btn_fourthly"
                  @click="onClickPopPriceAdd(item)"
                >
                  금액추가
                </button>
                <button
                  class="btn_small btn_fourthly"
                  @click="$emit('onClickPopPriceAddHistory', item)"
                >
                  추가금액 내역보기
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="tbl_comm tbl_view">
      <table>
        <colgroup>
          <col style="width: 192px" />
          <col />
          <col style="width: 50%" />
        </colgroup>
        <tbody>
          <tr>
            <th class="align_mid">총 기안금액</th>
            <td class="align_mid" sytle="height:56px">
              <div class="inner_mid">
                {{ totalDraftingPrice }}
              </div>
            </td>

            <td rowspan="3" class="td_nopad">
              <dl class="list_total">
                <dt>총 기안금액</dt>
                <dd>{{ totalDraftingPrice }}</dd>
                <dt>추가금액</dt>
                <dd>(+) {{ totalPurchasePrice }}</dd>
                <dt>정산금액</dt>
                <dd>(-) {{ statementTotalPrice | currency }}</dd>
                <dt class="dt_total">기안잔액</dt>
                <dd class="dd_total">
                  <span class="txt_price">{{ draftBalance }}</span>
                </dd>
              </dl>
            </td>
          </tr>
          <tr>
            <th class="align_mid">총 추가금액</th>
            <td class="align_mid" sytle="height:56px">
              <div class="inner_mid">
                {{ totalPurchasePrice }}
              </div>
            </td>
          </tr>
          <tr>
            <th class="align_mid">정산금액</th>
            <td class="align_mid" sytle="height:56px">
              <div class="inner_mid">
                <div class="row_pack">
                  <div class="area_left">
                    <span class="txt_view">{{ statementTotalPrice | currency }}</span>
                  </div>
                  <div class="area_right">
                    <button
                      class="btn_small btn_fourthly"
                      @click="$emit('onClickPopStatementHistory')"
                    >
                      내역보기
                    </button>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { addCommaForMoney, getMoneyToNumber } from "@/utils/stringUtils";

export default {
  name: "DraftPurchasePriceAdd",
  props: {
    purchasePriceList: Array,
    totalDraftingPrice: String,
    totalPurchasePrice: String,
    totalAddPrice: String,
    isView: Boolean,
    statementTotalPrice: [Number, String],
  },
  computed: {
    draftBalance() {
      return addCommaForMoney(
        getMoneyToNumber(this.totalDraftingPrice) +
          getMoneyToNumber(this.totalPurchasePrice) -
          getMoneyToNumber(this.statementTotalPrice),
      );
    },
  },
  methods: {
    onClickPopPriceAdd(item) {
      this.$emit("onClickPopPriceAdd", item);
    },
  },
};
</script>
